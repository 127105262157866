<template>
  <el-form
    ref="form"
    :model="form"
    :rules="formRules"
    class="sms-verification-form leading-none"
    hide-required-asterisk
    @submit.native.prevent
  >
    <!-- Phone form -->
    <div v-if="step === 1">
      <el-form-item
        prop="phoneNumber"
      >
        <p
          slot="label"
          class="text-xs text-grey-darkest leading-none"
        >
          {{ $t('user.enter_mobile') }}
        </p>
        <div class="relative">
          <VueTelInput
            v-model="form.phoneNumber"
            required
            :input-options="{showDialCode: true, placeholder: $t('user.enter_mobile')}"
            class="sms-verification-form__tel-input mt-2"
            @input="handlePhoneValidate"
            @blur="handleOnBlurPhone"
          >
            <template v-slot:arrow-icon>
              <i class="el-icon-caret-bottom" />
            </template>
          </VueTelInput>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button
          :disabled="otpLoading"
          size="small"
          @click="cancel"
        >
          {{ $t('actions.cancel') }}
        </el-button>
        <el-button
          :loading="otpLoading"
          type="primary"
          size="small"
          :disabled="!validPhone || otpLoading"
          @click="sendCode"
        >
          {{ $t('user.send_code') }}
        </el-button>
      </el-form-item>
    </div>
    <!-- Verify otp code -->
    <div v-else-if="step === 2">
      <div class="mt-5 my-10 text-xs">
        <span class="text-black mb-2">
          {{ $t('user.auth_sms[0]') }}
          <strong>{{ form.phoneNumber }}</strong>
          <div class="flex mt-2">
            <a
              class="text-blue hover:text-blue-darker cursor-pointer"
              @click="changeNumber"
            >
              {{ $t('user.auth_sms[1]') }}
            </a>
            <span
              class="ml-3"
              :class="[
                { 'text-grey cursor-not-allowed': !resendable },
                { 'cursor-pointer text-blue hover:text-blue-darker': resendable }
              ]"
              @click="resendCode"
            >
              {{ $t('user.send_code') }}
              <span v-if="!resendable"> ({{ counter }}s) </span>
            </span>
          </div>
        </span>
      </div>
      <el-form-item prop="token">
        <p
          slot="label"
          class="text-xs text-grey-darkest leading-none"
        >
          {{ $t('user.auth_sms[2]') }}
        </p>
        <CodeInput
          v-model="form.token"
          class="flex mt-2"
          :style="{width: '80%'}"
        />
      </el-form-item>
      <el-form-item class="mt-10 mb-0">
        <el-button
          size="small"
          :disabled="loading"
          @click="cancel"
        >
          {{ $t('actions.cancel') }}
        </el-button>
        <el-button
          :loading="loading"
          type="primary"
          size="small"
          :disabled="!form.token || loading"
          @click="submit"
        >
          {{ $t('actions.verify') }}
        </el-button>
      </el-form-item>
    </div>
  </el-form>
</template>

<script>
import { parsePhoneNumber } from 'awesome-phonenumber';
import { VueTelInput } from 'vue-tel-input';

import CodeInput from './CodeInput.vue';

export default {
  name: 'AuthSms',

  components: {
    CodeInput,
    VueTelInput,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    step: {
      type: Number,
      default: 1,
    },
    generateOtp: {
      type: Function,
      default: () => {},
    },
  },

  data() {
    const validatePhone = (rule, value, callback) => {
      if (this.validPhone && value.trim().length) {
        callback();
      } else {
        callback(new Error(this.$t('validations.valid', { value: this.$t('fields.phone_number') })));
      }
    };

    const self = this;

    return {
      otpLoading: false,
      timer: 120, // seconds
      timerId: null,
      counter: 0,
      resendable: true,

      validPhone: false,

      form: {
        uid: '',
        token: '',
        phoneNumber: '',
      },

      formRules: {
        token: [
          { required: true, message: self.$t('validations.valid', { value: this.$t('fields.code') }), trigger: 'blur' },
        ],
        phoneNumber: [
          {
            validator: validatePhone,
            trigger: 'blur',
          },
        ],
      },
    };
  },

  watch: {
    step(value) {
      this.form.token = '';

      // Clear timer from event loop queue
      if (this.timerId && value !== 2) {
        clearTimeout(this.timerId);
      }
    },
  },

  beforeDestroy() {
    if (this.timerId) {
      clearTimeout(this.timerId);
    }
  },

  methods: {
    changeNumber() {
      this.$emit('update-step', 1);
    },

    handlePhoneValidate(n, validator) {
      this.validPhone = validator.valid;
      this.$refs.form.validateField('phoneNumber');
    },

    handleOnBlurPhone() {
      this.$refs.form.validateField('phoneNumber');
    },

    cancel() {
      this.$emit('update-step', 1);
      this.$emit('back');
    },

    submit() {
      if (this.$refs.form) {
        this.$refs.form.validate((valid) => {
          if (valid) {
            const payload = { type: 'SMS_OTP', ...this.form };
            this.$emit('submit', payload);
          } else {
            return false;
          }
          return false;
        });
      }
    },

    countdown() {
      if (this.counter > 0) {
        this.timerId = setTimeout(() => {
          this.counter -= 1;
          this.countdown();
        }, 1000);
      } else {
        this.resendable = true;
      }
    },

    resendCode() {
      if (this.resendable) {
        this.counter = this.timer;
        this.countdown();
        this.sendCode();
        this.resendable = false;
      }
    },

    sendCode() {
      this.resendable = true;
      this.otpLoading = true;

      const pn = parsePhoneNumber(this.form.phoneNumber);
      const payload = {
        ...this.form,
        country: pn.regionCode,
      };

      // execute first recaptcha
      this.$recaptchaLoaded()
        .then(() => this.$recaptcha('generate_2fa_otp'))
        .then((token) => {
          payload.gToken = token;

          return this.generateOtp(payload);
        }).then((res) => {
          this.form.uid = res.uid;
          this.$emit('update-step', 2);
        })
        .catch((err) => {
          this.qrCode = '';
          // this.$message.error('Failed to send OTP code');
          this.$showError(this, err);
        })
        .finally(() => {
          this.otpLoading = false;
        });
    },
  },
};
</script>
<style src="vue-tel-input/dist/vue-tel-input.css"></style>
<style lang="scss" scoped>
.sms-verification-form {
  .el-form-item.is-error {
    .sms-verification-form__tel-input {
      border: solid 1px tomato !important;
    }
  }
  .el-form-item.is-success {
    .sms-verification-form__tel-input {
      border: solid 1px #67C23A !important;
    }
  }
  .el-form-item__label {
    line-height: 0;
    margin: 20px 0 10px;
  }
  .note {
    word-break: break-word;
  }
  &__tel-input {
    display: inline-flex;
    height: 32px !important;
    border-radius: 4px !important;
    border: 1px solid #DCDFE6 !important;
    box-shadow: none !important;
    width: 80%;

    .vti__selection {
      height: 30px;
    }

    input {
      font-size: 13px;
      color: #606266;
      border-radius: 4px !important;

      &::placeholder {
        color: #E0E2E5;
        opacity: 1;
      }
    }
  }
}
</style>

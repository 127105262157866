//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';

import AuthCard from './AuthCard.vue';

import authAppIcon from '../../assets/two-fa-authapp.svg';
import smsIcon from '../../assets/two-fa-sms.svg';


export default {
  name: 'TwoFactor',

  components: {
    AuthCard,
  },

  props: {
    twoFa: {
      type: Array,
      default: () => [],
    },

    userData: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      authAppIcon,
      smsIcon,
      defaultAuth: '',
      loading: false,
      enforce2fa: false,
    };
  },

  watch: {
    twoFa() {
      const twoFaAuth = this.twoFa.find(d => d.default);
      if (twoFaAuth) {
        this.defaultAuth = twoFaAuth.type;
      }
    },
  },

  created() {
    // Loading only shows first
    this.loading = true;

    if (this.userData.AccountConfig) {
      const accountConfig = JSON.parse(this.userData.AccountConfig);
      this.enforce2fa = accountConfig.Enforce2FA;
    }
    this.fetchData();
  },

  methods: {
    ...mapActions({
      updateTwoFA: 'account/updateTwoFA',
      removeAllTwoFa: 'account/removeAllTwoFa',
      removeTwoFa: 'account/removeTwoFa',
      setDefaultTwoFa: 'account/setDefaultTwoFa',
      validatePassword: 'account/validatePassword',
    }),

    fetchData() {
      this.updateTwoFA()
        .then(() => {
          const twoFaAuth = this.twoFa.find(d => d.default);
          this.defaultAuth = (twoFaAuth && twoFaAuth.type) || null;
        })
        .catch((err) => {
          this.$showError(this, err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    setDefault(p) {
      if (p.type !== this.defaultAuth) {
        const oldDefaultAuth = this.defaultAuth;
        this.defaultAuth = p.type;
        const payload = { type: p.type === 'AUTHENTICATOR_APP' ? 'sms' : 'app' };
        this.setDefaultTwoFa(payload)
          .then(() => {
            this.fetchData();
          })
          .catch((err) => {
            this.defaultAuth = oldDefaultAuth;
            // this.$message.error(this.$t('errors.default'));
            this.$showError(this, err);
          });
      }
    },

    authDefaultValue(type) {
      return this.defaultAuth === type;
    },

    // Get enabled two-factor auth
    enabledTwoFa() {
      return this.twoFa.filter(d => d.enabled);
    },

    enabledSMSTwoFa() {
      return this.twoFa.filter(d => (d.enabled && d.type === 'SMS_OTP'));
    },

    validateAction(password, callback) {
      let user = {};

      try {
        const cpv3User = localStorage.getItem('CPV3_User') || {};
        user = JSON.parse(cpv3User);
      } catch (e) {
        // d
      }
      const payload = {
        id: user.UserId,
        username: user.Login,
        password,
      };

      this.validatePassword(payload)
        .then(() => {
          callback();
        })
        .catch((err) => {
          // this.$message.error(err.message || this.$t('errors.default'));
          this.$showError(this, err);
        });
    },

    removeAuth(type) {
      this.$prompt(this.$t('user.remove_all_prompt[0]'), this.$t('user.remove_prompt[0]'), {
        confirmButtonText: this.$t('user.remove_prompt[1]'),
        cancelButtonText: this.$t('actions.cancel'),
        inputPattern: /^(?!\s*$).+/,
        inputErrorMessage: this.$t('validations.valid', { value: this.$t('fields.password') }),
        inputType: 'password',
      }).then(({ value }) => {
        this.removeTwoFa({ type, password: value })
          .then(() => {
            this.fetchData();
          }).catch((err) => {
            this.$showError(this, err);
          });

        // this.validateAction(
        //   value,
        //   () => {
        //     this.removeTwoFa({ type, password: value })
        //       .then(() => {
        //         this.fetchData();
        //       }).catch((err) => {
        //         this.$showError(this, err);
        //         // this.$message.error(err.message || this.$t('errors.default'));
        //       });
        //   },
        // );
      }).catch(() => {});
    },

    removeAllAuth() {
      this.$prompt(this.$t('user.remove_all_prompt[0]'), this.$t('user.remove_all_prompt[1]'), {
        confirmButtonText: this.$t('user.remove_all_prompt[2]'),
        cancelButtonText: this.$t('actions.cancel'),
        inputPattern: /^(?!\s*$).+/,
        inputErrorMessage: this.$t('validations.valid', { value: this.$t('fields.password') }),
        inputType: 'password',
      }).then(({ value }) => {
        this.removeAllTwoFa({ password: value })
          .then(() => {
            this.fetchData();
          }).catch((err) => {
            this.$showError(this, err);
          });

        // this.validateAction(
        //   value,
        //   () => {
        //     this.removeAllTwoFa({ password: value })
        //       .then(() => {
        //         this.fetchData();
        //       }).catch((err) => {
        //         // this.$message.error(err.message || this.$t('errors.default'));
        //         this.$showError(this, err);
        //       });
        //   },
        // );
      }).catch(() => {});
    },

    getAuthData(type) {
      return this.twoFa.find(a => a.type.toLowerCase() === type.toLowerCase());
    },

    enableAuthForm(code) {
      this.$emit('enableForm', code);
    },

    getCustomerType() {
      let user = {};
      try {
        const cpv3User = localStorage.getItem('CPV3_User') || {};
        user = JSON.parse(cpv3User);
      } catch (e) {
      }
      return user.CustomerType;
    },
  },
};

<template>
  <div>
    <!-- If SSO is enabled -->
    <div v-if="userData.SSOEnabled">
      <i18n
        path="user.sso_enabled"
        tag="p"
        class="text-grey mt-2"
      >
        <template #break>
          <br>
        </template>
      </i18n>
    </div>
    <div v-else>
      <template v-if="enabledTwoFa().length">
        <h4 class="mt-2 text-lg font-normal">
          <i class="el-icon-success text-green-light" />
          {{ $t('user.2fa_enabled[0]') }}
        </h4>
        <p class="text-grey mt-2">
          {{ $t('user.2fa_enabled[1]') }}
        </p>
      </template>
      <template v-else>
        <div v-if="enforce2fa" class="p-2 bg-['#ef5753']" style="background-color: #f9acaa;">
          <span class="mt-2 text-sm text-red font-normal">
            Your admin has enforced you to setup 2FA
          </span>
        </div>
        <h4 class="mt-2 text-lg font-normal">
          {{ $t('user.2fa_disabled[0]') }}
        </h4>
        <p class="text-grey mt-2">
          {{ $t('user.2fa_disabled[1]') }}
        </p>
      </template>
      <div
        v-loading="loading"
        class="my-12 flex -mx-2"
      >
        <div class="px-2 w-full">
          <auth-card
            :image="authAppIcon"
            :data="getAuthData('AUTHENTICATOR_APP')"
            :default-value="authDefaultValue('AUTHENTICATOR_APP')"
            :name="$t('user.2fa[1].name')"
            :description="$t('user.2fa[1].description')"
            recommend
            @set-default="setDefault"
            @enable="enableAuthForm('google')"
            @remove="removeAuth('app')"
          />
        </div>
        <template v-if="['E', 'I'].includes(getCustomerType())">
          <div class="px-2 w-full">
            <auth-card
              :image="smsIcon"
              :data="getAuthData('SMS_OTP')"
              :default-value="authDefaultValue('SMS_OTP')"
              :name="$t('user.2fa[0].name')"
              :description="$t('user.2fa[0].description')"
              @set-default="setDefault"
              @enable="enableAuthForm('sms')"
              @remove="removeAuth('sms')"
            />
          </div>
        </template>
        <template v-else>
          <template v-if="enabledSMSTwoFa().length">
            <div class="px-2 w-full">
              <auth-card
                :image="smsIcon"
                :data="getAuthData('SMS_OTP')"
                :default-value="authDefaultValue('SMS_OTP')"
                :name="$t('user.2fa[0].name')"
                :description="$t('user.2fa[0].description')"
                @set-default="setDefault"
                @enable="enableAuthForm('sms')"
                @remove="removeAuth('sms')"
              />
            </div>
          </template>
          <template v-else>
            <div class="px-2 w-full" />
          </template>
        </template>
      </div>
      <a
        v-if="!enabledTwoFa().length"
        class="text-sm text-blue cursor-pointer mt-10 hover:text-blue-dark"
        @click="enableAuthForm('help')"
      >
        {{ $t('user.how_2fa_works') }}
      </a>
      <el-button
        v-else-if="enabledTwoFa().length >= twoFa.length"
        type="danger"
        size="mini"
        class="mt-10"
        @click="removeAllAuth"
      >
        {{ $t('user.remove_2fa_all') }}
      </el-button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import AuthCard from './AuthCard.vue';

import authAppIcon from '../../assets/two-fa-authapp.svg';
import smsIcon from '../../assets/two-fa-sms.svg';


export default {
  name: 'TwoFactor',

  components: {
    AuthCard,
  },

  props: {
    twoFa: {
      type: Array,
      default: () => [],
    },

    userData: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      authAppIcon,
      smsIcon,
      defaultAuth: '',
      loading: false,
      enforce2fa: false,
    };
  },

  watch: {
    twoFa() {
      const twoFaAuth = this.twoFa.find(d => d.default);
      if (twoFaAuth) {
        this.defaultAuth = twoFaAuth.type;
      }
    },
  },

  created() {
    // Loading only shows first
    this.loading = true;

    if (this.userData.AccountConfig) {
      const accountConfig = JSON.parse(this.userData.AccountConfig);
      this.enforce2fa = accountConfig.Enforce2FA;
    }
    this.fetchData();
  },

  methods: {
    ...mapActions({
      updateTwoFA: 'account/updateTwoFA',
      removeAllTwoFa: 'account/removeAllTwoFa',
      removeTwoFa: 'account/removeTwoFa',
      setDefaultTwoFa: 'account/setDefaultTwoFa',
      validatePassword: 'account/validatePassword',
    }),

    fetchData() {
      this.updateTwoFA()
        .then(() => {
          const twoFaAuth = this.twoFa.find(d => d.default);
          this.defaultAuth = (twoFaAuth && twoFaAuth.type) || null;
        })
        .catch((err) => {
          this.$showError(this, err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    setDefault(p) {
      if (p.type !== this.defaultAuth) {
        const oldDefaultAuth = this.defaultAuth;
        this.defaultAuth = p.type;
        const payload = { type: p.type === 'AUTHENTICATOR_APP' ? 'sms' : 'app' };
        this.setDefaultTwoFa(payload)
          .then(() => {
            this.fetchData();
          })
          .catch((err) => {
            this.defaultAuth = oldDefaultAuth;
            // this.$message.error(this.$t('errors.default'));
            this.$showError(this, err);
          });
      }
    },

    authDefaultValue(type) {
      return this.defaultAuth === type;
    },

    // Get enabled two-factor auth
    enabledTwoFa() {
      return this.twoFa.filter(d => d.enabled);
    },

    enabledSMSTwoFa() {
      return this.twoFa.filter(d => (d.enabled && d.type === 'SMS_OTP'));
    },

    validateAction(password, callback) {
      let user = {};

      try {
        const cpv3User = localStorage.getItem('CPV3_User') || {};
        user = JSON.parse(cpv3User);
      } catch (e) {
        // d
      }
      const payload = {
        id: user.UserId,
        username: user.Login,
        password,
      };

      this.validatePassword(payload)
        .then(() => {
          callback();
        })
        .catch((err) => {
          // this.$message.error(err.message || this.$t('errors.default'));
          this.$showError(this, err);
        });
    },

    removeAuth(type) {
      this.$prompt(this.$t('user.remove_all_prompt[0]'), this.$t('user.remove_prompt[0]'), {
        confirmButtonText: this.$t('user.remove_prompt[1]'),
        cancelButtonText: this.$t('actions.cancel'),
        inputPattern: /^(?!\s*$).+/,
        inputErrorMessage: this.$t('validations.valid', { value: this.$t('fields.password') }),
        inputType: 'password',
      }).then(({ value }) => {
        this.removeTwoFa({ type, password: value })
          .then(() => {
            this.fetchData();
          }).catch((err) => {
            this.$showError(this, err);
          });

        // this.validateAction(
        //   value,
        //   () => {
        //     this.removeTwoFa({ type, password: value })
        //       .then(() => {
        //         this.fetchData();
        //       }).catch((err) => {
        //         this.$showError(this, err);
        //         // this.$message.error(err.message || this.$t('errors.default'));
        //       });
        //   },
        // );
      }).catch(() => {});
    },

    removeAllAuth() {
      this.$prompt(this.$t('user.remove_all_prompt[0]'), this.$t('user.remove_all_prompt[1]'), {
        confirmButtonText: this.$t('user.remove_all_prompt[2]'),
        cancelButtonText: this.$t('actions.cancel'),
        inputPattern: /^(?!\s*$).+/,
        inputErrorMessage: this.$t('validations.valid', { value: this.$t('fields.password') }),
        inputType: 'password',
      }).then(({ value }) => {
        this.removeAllTwoFa({ password: value })
          .then(() => {
            this.fetchData();
          }).catch((err) => {
            this.$showError(this, err);
          });

        // this.validateAction(
        //   value,
        //   () => {
        //     this.removeAllTwoFa({ password: value })
        //       .then(() => {
        //         this.fetchData();
        //       }).catch((err) => {
        //         // this.$message.error(err.message || this.$t('errors.default'));
        //         this.$showError(this, err);
        //       });
        //   },
        // );
      }).catch(() => {});
    },

    getAuthData(type) {
      return this.twoFa.find(a => a.type.toLowerCase() === type.toLowerCase());
    },

    enableAuthForm(code) {
      this.$emit('enableForm', code);
    },

    getCustomerType() {
      let user = {};
      try {
        const cpv3User = localStorage.getItem('CPV3_User') || {};
        user = JSON.parse(cpv3User);
      } catch (e) {
      }
      return user.CustomerType;
    },
  },
};
</script>

<template>
  <div>
    <nav class="fixed w-full h-12 pin-l pin-t z-50 nv-topbar">
      <div class="flex justify-between">
        <div class="flex">
          <Logo
            aria-label="8x8"
            class="nv-sidebar__logo"
          />
          <span class="nv-topbar__title block ml-2 font-semibold text-base flex items-center">Connect</span>
          <div
            v-if="isImpersonation"
            class="ml-2 rounded font-medium text-sm flex items-center"
          >
            <span class="text-xs inline-block text-black rounded bg-yellow-dark px-2 py-1">
              <p class="impersonation-text-anim capitalize">
                {{ $t("user.impersonating") }}
              </p>
            </span>
          </div>
        </div>
        <div class="flex items-center h-full justify-center">
          <div
            v-if="showBalanceAlert()"
            class="px-4 py-2 z-50 rounded bg-yellow-lighter text-grey-darker text-xs nv-balance-alert-top"
          >
            <span>
              {{ $t("warning.balance_alert[0]") }}
              {{ userData.Currency }}
              {{
                Number(userData.LowBalanceThreshold).toLocaleString("en-US", {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })
              }}.
              {{ $t("warning.balance_alert[1]") }}
              <a
                :href="`mailto:${supportEmail}`"
                class="text-blue no-underline"
              >{{ supportEmail }}</a>
            </span>
          </div>
        </div>

        <!-- Widget -->
        <div class="">
          <Widgets
            :user-data="userData"
            :dark-mode="!!darkMode"
            :is-show-otp="showOTP"
            :is-show-balance="isShowBalance"
            :is-impersonation="isImpersonation"
            :has-free-credits="hasFreeCredits"
            :balance="balance"
            :enable-manage-user="authUserMangement"
            :is-show-oxygen-toggle-btn="Object.keys(userData).length &&
              (userData.CustomerType === 'I' ||
              userData.BetaFeatures.includes('WEB_Oxygen_Theme_V1'))
            "
            :oxygen-mode="oxygenMode"
            @select-option="handleCommand"
            @click-whats-new="handleWhatsNew"
            @toggle-theme="toggleDarkMode(!darkMode)"
            @show-otp="showOTPModal"
            @toggle-oxygen-theme="() => {toggleOxygenTheme();}"
          />
        </div>
      </div>
    </nav>

    <aside
      class="nv-sidebar nv-sidebar--small z-20 fixed pin-l h-full"
      :class="[
        { 'nv-sidebar--dark': darkMode },
        { 'nv-sidebar--compact': compactSidebar },
      ]"
    >
      <div class="nv-sidebar__inner">
        <nav class="nv-sidebar__nav">
          <!-- Overview -->
          <el-tooltip
            :content="$t('sidebar_menu[0]')"
            placement="right"
            open-delay="500"
            :effect="darkMode ? 'dark' : 'light'"
            :visible-arrow="false"
            :disabled="!compactSidebar"
          >
            <router-link
              to="/"
              class="nv-sidebar__link"
              :class="addActiveClass()"
            >
              <i class="icon-novo-dashboard nv-sidebar__icon" />
              <span class="nv-sidebar__label">
                {{ $t("sidebar_menu[0]") }}
              </span>
            </router-link>
          </el-tooltip>

          <!-- Sender -->
          <el-tooltip
            v-if="isPackageLimit('cp-sender')"
            :content="$t('sidebar_menu[1]')"
            placement="right"
            open-delay="500"
            :effect="darkMode ? 'dark' : 'light'"
            :visible-arrow="Boolean(true)"
            :disabled="!compactSidebar"
          >
            <router-link
              to="/multichannel-sender/send"
              class="nv-sidebar__link"
              :class="addActiveClass('/multichannel-sender/send')"
            >
              <i class="icon-novo-send nv-sidebar__icon" />
              <span class="nv-sidebar__label">{{ $t("sidebar_menu[1]") }}</span>
            </router-link>
          </el-tooltip>

          <el-tooltip
            v-if="isPackageLimit('cp-campaign')"
            :content="$t('sidebar_menu[2]')"
            placement="right"
            open-delay="500"
            :effect="darkMode ? 'dark' : 'light'"
            :visible-arrow="Boolean(true)"
            :disabled="!compactSidebar"
          >
            <!-- Campaigns -->
            <router-link
              to="/multichannel-sender"
              class="nv-sidebar__link"
              :class="addActiveClass('/multichannel-sender')"
            >
              <i class="icon-novo-voice nv-sidebar__icon" />
              <span class="nv-sidebar__label">{{ $t("sidebar_menu[2]") }}</span>
            </router-link>
          </el-tooltip>

          <el-tooltip
            v-if="hasAutomation() || isPackageLimit('cp-automation')"
            :content="$t('sidebar_menu[12]')"
            placement="right"
            open-delay="500"
            :effect="darkMode ? 'dark' : 'light'"
            :visible-arrow="Boolean(true)"
            :disabled="!compactSidebar"
          >
            <!-- Automation -->
            <router-link
              to="/automation"
              class="nv-sidebar__link"
              :class="addActiveClass('/automation')"
            >
              <i class="icon-novo-logic nv-sidebar__icon" />
              <span class="nv-sidebar__label">{{
                $t("sidebar_menu[12]")
              }}</span>
            </router-link>
          </el-tooltip>

          <el-tooltip
            v-if="isPackageLimit('cp-contacts')"
            :content="$t('sidebar_menu[3]')"
            placement="right"
            open-delay="500"
            :effect="darkMode ? 'dark' : 'light'"
            :visible-arrow="Boolean(true)"
            :disabled="!compactSidebar"
          >
            <!-- Contacts -->
            <router-link
              to="/messaging/contacts"
              class="nv-sidebar__link"
              :class="addActiveClass('/messaging/contacts')"
            >
              <i class="icon-novo-directory nv-sidebar__icon" />
              <span class="nv-sidebar__label">{{ $t("sidebar_menu[3]") }}</span>
            </router-link>
          </el-tooltip>

          <el-tooltip
            v-if="isPackageLimit('cp-templates')"
            :content="$t('sidebar_menu[4]')"
            placement="right"
            open-delay="500"
            :effect="darkMode ? 'dark' : 'light'"
            :visible-arrow="Boolean(true)"
            :disabled="!compactSidebar"
          >
            <!-- Templates -->
            <router-link
              to="/messaging/templates"
              class="nv-sidebar__link"
              :class="addActiveClass('/messaging/templates')"
            >
              <i class="icon-novo-copy nv-sidebar__icon" />
              <span class="nv-sidebar__label">{{ $t("sidebar_menu[4]") }}</span>
            </router-link>
          </el-tooltip>

          <!-- App Routes -->
          <div
            v-if="!compactSidebar"
            class="nv-sidebar__subnav"
          >
            <Dropdown
              v-for="appName in Object.keys(appRoutes)"
              :id="appRoutes[appName].id"
              :key="appName"
              :curr-open-app="openedAppMenu"
              :app-name="appName"
              @open-app-menu="(appName) => (openedAppMenu = appName)"
            >
              <template
                v-if="isPackageLimit_v2(appName)"
                v-slot:button
              >
                <span class="nv-sidebar__text nv-sidebar__text--hover-highlight">
                  <i :class="`${getIcon(appName)} nv-sidebar__icon align-middle`" />
                  <span class="nv-sidebar__label">{{
                    getIconLabel(appName)
                  }}</span>
                  <WA-popover
                    v-if="appName.toLocaleLowerCase() === 'chat apps' && (showPrepaidCA() || (isNewWARegistered() && isNewToWhatsApp)) && Object.keys(userData).length && hasDoneWARequest"
                    :dark-mode="darkMode"
                    :visible="waPopoverVisible"
                    :is-prepaid-ca="showPrepaidCA()"
                    :is-newly-registered="isNewWARegistered() && isNewToWhatsApp"
                    @close-wa-popover="(isNewRegistered) => { closeWAPopover(isNewRegistered) }"
                    @open-wa-popover="(isNewRegistered) => { openWAPopover(isNewRegistered) }"
                  />
                  <i class="nv-sidebar__right-icon el-icon-arrow-down" />
                </span>
              </template>
              <template v-slot:options>
                <div class="nv-sidebar__group">
                  <nav class="nv-sidebar__group-item">
                    <div>
                      <router-link
                        v-for="(route, index) in appRoutes[appName].routes"
                        :key="index"
                        :ref="route.ref || ''"
                        :to="route.url || ''"
                        :class="`${addActiveClass(route.url)}`"
                        class="nv-sidebar__link nv-sidebar__link--inner"
                      >
                        {{ $t(`sidebar_menu_children.${route.name}`) }}
                      </router-link>
                    </div>
                  </nav>
                </div>
              </template>
            </Dropdown>
          </div>
          <nav
            v-else
            class="nv-sidebar__subapps"
          >
            <div
              v-for="appName in Object.keys(appRoutes)"
              :ref="appName"
              :key="appName"
              class="block nv-sidebar__expand-trigger"
            >
              <el-popover
                placement="right"
                width="220"
                trigger="hover"
                popper-class="nv-sidebar-popover"
                @hide="() => { waPopoverVisible = false; }"
              >
                <router-link
                  v-if="isPackageLimit_v2(appName)"
                  slot="reference"
                  :to="appRoutes[appName].url || ''"
                  active-class="nv-sidebar__link--active shadow"
                  class="nv-sidebar__link nv-sidebar__link--text"
                >
                  <i :class="`${getIcon(appName)} nv-sidebar__icon`" />
                </router-link>
                <div class="nv-sidebar__expandable-nav">
                  <div class="normal-case">
                    <h1 class="text-base text-black">
                      <i
                        :class="`${getIcon(
                          appName
                        )} nv-sidebar__icon font-bold`"
                      />
                      <span class="nv-sidebar__label--inner">{{
                        getIconLabel(appName)
                      }}</span>
                      <WA-popover
                        v-if="appName.toLocaleLowerCase() === 'chat apps' && (showPrepaidCA() || (isNewWARegistered() && isNewToWhatsApp)) && Object.keys(userData).length && hasDoneWARequest"
                        :dark-mode="darkMode"
                        :visible="waPopoverVisible"
                        :is-prepaid-ca="showPrepaidCA()"
                        :is-newly-registered="isNewWARegistered() && isNewToWhatsApp"
                        @close-wa-popover="(isNewRegistered) => { closeWAPopover(isNewRegistered) }"
                        @open-wa-popover="(isNewRegistered) => { openWAPopover(isNewRegistered) }"
                      />
                    </h1>
                    <nav
                      tabindex="-1"
                      class="nv-sidebar__expandable-list"
                    >
                      <router-link
                        v-for="(route, index) in appRoutes[appName].routes"
                        :key="index"
                        :ref="route.ref || ''"
                        :to="route.url || ''"
                        :class="addActiveClass(
                          route.url,
                          'nv-sidebar__expandable-item--active'
                        )
                        "
                        class="nv-sidebar__expandable-item text-sm font-normal"
                        @click="() => (openedAppMenu = appName)"
                      >
                        {{ $t(`sidebar_menu_children.${route.name}`) }}
                      </router-link>
                    </nav>
                  </div>
                </div>
              </el-popover>
            </div>
          </nav>

          <el-tooltip
            v-if="isPackageLimit('cp-api-keys')"
            :content="$t('sidebar_menu[5]')"
            placement="right"
            open-delay="500"
            :effect="darkMode ? 'dark' : 'light'"
            :visible-arrow="Boolean(true)"
            :disabled="!compactSidebar"
          >
            <!-- API keys -->
            <router-link
              id="api-keys"
              to="/messaging/api-keys"
              class="nv-sidebar__link"
              :class="addActiveClass('/messaging/api-keys')"
            >
              <i class="icon-novo-api nv-sidebar__icon" />
              <span class="nv-sidebar__label">{{ $t("sidebar_menu[5]") }}</span>
            </router-link>
          </el-tooltip>

          <el-tooltip
            v-if="isPackageLimit('cp-webhooks')"
            :content="$t('sidebar_menu[6]')"
            placement="right"
            open-delay="500"
            :effect="darkMode ? 'dark' : 'light'"
            :visible-arrow="Boolean(true)"
            :disabled="!compactSidebar"
          >
            <!-- Webhooks -->
            <router-link
              to="/webhooks"
              class="nv-sidebar__link"
              :class="addActiveClass('/webhooks')"
            >
              <i class="icon-novo-transfer nv-sidebar__icon" />
              <span class="nv-sidebar__label">{{ $t("sidebar_menu[6]") }}</span>
            </router-link>
          </el-tooltip>

          <el-tooltip
            v-if="isPackageLimit('cp-number')"
            :content="$t('sidebar_menu[7]')"
            placement="right"
            open-delay="500"
            :effect="darkMode ? 'dark' : 'light'"
            :visible-arrow="Boolean(true)"
            :disabled="!compactSidebar"
          >
            <!-- Numbers -->
            <router-link
              to="/messaging/virtual-numbers"
              class="nv-sidebar__link"
              :class="addActiveClass('/messaging/virtual-numbers')"
            >
              <i class="icon-novo-number nv-sidebar__icon" />
              <span class="nv-sidebar__label">{{ $t("sidebar_menu[7]") }}</span>
            </router-link>
          </el-tooltip>

          <el-tooltip
            v-if="isPackageLimit('cp-pricing')"
            :content="$t('sidebar_menu[8]')"
            placement="right"
            open-delay="500"
            :effect="darkMode ? 'dark' : 'light'"
            :visible-arrow="Boolean(true)"
            :disabled="!compactSidebar"
          >
            <!-- Pricing -->
            <router-link
              to="/pricing"
              class="nv-sidebar__link"
              :class="addActiveClass('/pricing')"
            >
              <i class="icon-novo-pricetag nv-sidebar__icon" />
              <span class="nv-sidebar__label">{{ $t("sidebar_menu[8]") }}</span>
            </router-link>
          </el-tooltip>

          <el-tooltip
            v-if="authUserMangement && isPackageLimit('cp-user-mngt')"
            :content="$t('sidebar_menu[9]')"
            placement="right"
            open-delay="500"
            :effect="darkMode ? 'dark' : 'light'"
            :visible-arrow="Boolean(true)"
            :disabled="!compactSidebar"
          >
            <!-- Users -->
            <router-link
              to="/user-management"
              class="nv-sidebar__link"
              :class="addActiveClass('/user-management')"
            >
              <i class="icon-novo-users nv-sidebar__icon" />
              <span class="nv-sidebar__label">{{ $t("sidebar_menu[9]") }}</span>
            </router-link>
          </el-tooltip>

          <el-tooltip
            v-if="hasPaymentAccess && isPackageLimit('cp-payment')"
            :content="$t('sidebar_menu[10]')"
            placement="right"
            open-delay="500"
            :effect="darkMode ? 'dark' : 'light'"
            :visible-arrow="Boolean(true)"
            :disabled="!compactSidebar"
          >
            <router-link
              id="payment-page-link"
              to="/messaging/payment"
              class="nv-sidebar__link"
              :class="addActiveClass('/messaging/payment')"
            >
              <i class="icon-novo-star nv-sidebar__icon" />
              <span class="nv-sidebar__label">{{
                $t("sidebar_menu[10]")
              }}</span>
            </router-link>
          </el-tooltip>

          <el-tooltip
            v-if="isPackageLimit('cp-support')"
            :content="$t('sidebar_menu[11]')"
            placement="right"
            open-delay="500"
            :effect="darkMode ? 'dark' : 'light'"
            :visible-arrow="Boolean(true)"
            :disabled="!compactSidebar"
          >
            <router-link
              id="support-page-link"
              to="/support"
              class="nv-sidebar__link"
              :class="addActiveClass('/support')"
            >
              <span class="icon-novo-support nv-sidebar__icon" />
              <span class="nv-sidebar__label">{{
                $t("sidebar_menu[11]")
              }}</span>
            </router-link>
          </el-tooltip>
        </nav>

        <WA-info-dialog
          :visible="waDialogVisible"
          :dark-mode="darkMode"
          @close-dialog="(obj) => { closeWAInfoDialog(obj) }"
        />

        <el-dialog
          :visible.sync="showProfileDialog"
          :show-close="!modalScreen"
          :center="modalScreen && modalScreen.match(/help|suggest-enable/)"
          append-to-body="true"
          :before-close="beforeDialogClose"
          @close="resetForm = true"
          @closed="closeModal"
        >
          <template
            v-if="modalScreen && modalScreen.match(/google/) && !userData.SSOEnabled
            "
          >
            <span slot="title">
              <div class="flex">
                <span>
                  <i
                    class="el-icon-back mr-2 hover:text-blue cursor-pointer"
                    @click="backModal"
                  />
                </span>
                <div>
                  <p>{{ $t("user.setup_auth[0]") }}</p>
                  <p class="mt-2 text-grey text-xs">
                    {{ $t("user.setup_auth[1]") }}
                  </p>
                </div>
              </div>
            </span>
            <auth-google
              :loading="verificationLoading"
              :generate-qr="getTwoFaQrCode"
              @submit="submit"
              @back="backModal"
            />
          </template>
          <template
            v-else-if="modalScreen && modalScreen.match(/sms/) && !userData.SSOEnabled
            "
          >
            <span slot="title">
              <div class="flex">
                <span>
                  <i
                    class="el-icon-back mr-2 hover:text-blue cursor-pointer"
                    :class="[
                      { 'hover:text-blue cursor-pointer': !verifcationLoading },
                      { 'text-grey cursor-not-allowed': verifcationLoading },
                    ]"
                    @click="backModalStep"
                  />
                </span>
                <div>
                  <p>{{ getStepLabel("sms").title }}</p>
                  <p class="mt-2 text-grey text-xs">
                    {{ getStepLabel("sms").text }}
                  </p>
                </div>
              </div>
            </span>
            <auth-sms
              :loading="verificationLoading"
              :step="validationStep"
              :generate-otp="getTwoFaOtpCode"
              @submit="submit"
              @back="backModal"
              @update-step="updateValidationStep"
            />
          </template>
          <template
            v-else-if="modalScreen && modalScreen.match(/help/) && !userData.SSOEnabled
            "
          >
            <span slot="title">
              <p class="text-xl">{{ $t("user.setup_how_work[0]") }}</p>
              <p class="mt-5 text-grey text-sm mx-20">
                {{ $t("user.setup_how_work[1]") }}
              </p>
            </span>
            <auth-help @back="backModal" />
          </template>
          <template
            v-else-if="modalScreen &&
              modalScreen.match(/suggest-enable/) &&
              !userData.SSOEnabled
            "
          >
            <span slot="title">
              <p class="text-xl">{{ $t("user.two_factor_security[0]") }}</p>
              <p class="mt-5 text-grey text-sm mx-20">
                {{ $t("user.two_factor_security[1]") }}
              </p>
            </span>
            <auth-suggest-enable
              @setup-two-fa="setupTwoFa()"
              @close="showProfileDialog = false"
            />
            <span slot="footer">
              <div class="w-full text-right">
                <el-checkbox v-model="suggestEnableAuth">{{
                  $t("actions.dont_show_again")
                }}</el-checkbox>
              </div>
            </span>
          </template>
          <template v-else>
            <span slot="title">{{ $t("user.profile_settings") }}</span>
          </template>

          <div v-show="!modalScreen">
            <settings
              :two-fa="getTwoFA"
              :tab="tab"
              :reset-form="resetForm"
              :user-data.sync="userData"
              @updateName="setName"
              @enableForm="enableForm"
            />
          </div>
        </el-dialog>
      </div>
      <div
        class="nv-sidebar__expand-button"
        @click="toggleSidebar(!compactSidebar)"
      >
        <div class="nv-sidebar__expand-button--inner">
          <i
            :class="`icon-novo-${!compactSidebar ? 'double-arrow-left' : 'double-arrow-right'
            } nv-sidebar__icon`"
          />
          <span class="nv-sidebar__label">Collapse</span>
        </div>
      </div>
    </aside>
  </div>
</template>

<script>
import { Promise } from 'es6-promise';
import _ from 'lodash';
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';
// import singleSpa from 'single-spa';
import Moment from 'moment';
import http from '../../utils/http';
import Settings from './Settings.vue';
import AuthGoogle from './partials/AuthGoogle.vue';
import AuthHelp from './partials/AuthHelp.vue';
import AuthSms from './partials/AuthSms.vue';
import AuthSuggestEnable from './partials/AuthSuggestEnable.vue';
import Dropdown from './partials/Dropdown.vue';
// import FreeCreditsOtp from './partials/FreeCreditsOtp.vue';
import Logo from './partials/Logo.vue';
import Widgets from './partials/Widgets.vue';
import WAPopover from './partials/WAPopover.vue';
import WAInfoDialog from './partials/WAInfoDialog.vue';
// import handleErrors from '../common/handle-api-errors';

import appRoutes from './json/appRoutes.json';
import { handlePackageLimit } from '../common/handle-package-limit';

export default {
  name: 'App',

  components: {
    Settings,
    AuthGoogle,
    AuthSms,
    AuthHelp,
    AuthSuggestEnable,
    Logo,
    Dropdown,
    Widgets,
    WAPopover,
    WAInfoDialog,
    // FreeCreditsOtp,
  },

  data() {
    const self = this;

    return {
      activeRoute: '/',
      appRoutes,
      hasSenderIdAccess: false,
      suggestEnableAuth: false,
      verificationLoading: false,
      validationStep: 1,
      stepsHeader: {
        sms: [
          {
            title: self.$t('user.sms_verification_steps[0].title'),
            text: self.$t('user.sms_verification_steps[0].text'),
          },
          {
            title: self.$t('user.sms_verification_steps[1].title'),
            text: self.$t('user.sms_verification_steps[1].text'),
          },
        ],
      },

      modalScreen: null,
      authTokenKey: 'WWW-Authenticate',
      name: 'User',
      needMigrationFromV2: false,
      userId: '',
      login: '',
      authUserMangement: false,

      hasPaymentAccess: false,
      // hasOldCPAccess: false,
      hasFreeCredits: false,
      userCountryCode: '',
      isUserCountryWhiteListed: false,

      showProfileDialog: false,
      resetForm: false,
      tab: 'profile',
      isAdmin: false,
      isFetching: true,
      userData: {},

      darkMode: false,
      compactSidebar: false,
      oxygenMode: true,

      openedAppMenu: '',

      passwordExpiring: false,
      passwordExpired: false,
      passwordResetSkipped: null,
      showPasswordExpiring: false,

      showOTP: false,
      isModalShow: false,
      isShowBalance: false,
      isImpersonation: Boolean(localStorage.getItem('cpv3Impersonate')),

      loading: false,
      prevUrl: null,
      supportEmail: this.appConfig.supportEmail,
      enforce2fa: false,
      show2faSetting: false,
      messageBalance: {},
      waPopoverVisible: false,
      waDialogVisible: false,
      isNewToWhatsApp: false,
      hasDoneWARequest: false,
    };
  },

  computed: {
    ...mapGetters({
      getTwoFA: 'account/getTwoFA',
      balance: 'common/balance',
      whiteListedCountries: 'common/whitelistedCountries',
    }),

    ...mapState('common', ['timezones']),

    formatBalance() {
      return (val) => {
        const d = this.$constants.DECIMAL_PLACES;
        const l = this.$constants.LOCALE;

        if (!val) {
          return '0.00';
        }

        return this.$wc.formatPrice(val, d, l);
      };
    },
  },

  beforeDestroy() {
    this.$el.className = '';

    // Remove single-spa custom event
    window.removeEventListener(
      'single-spa:routing-event',
      this.singleSpaRouteListener,
    );
  },

  created() {
    // detect user location
    this.fetchUserCountry();

    // Opens the app drawer if any subapp is active on load
    this.expandAppDrawer();

    // Listen to single-spa custom even for app changes
    window.addEventListener(
      'single-spa:routing-event',
      this.singleSpaRouteListener,
      false,
    );
    window.addEventListener('fetchUserData', this.fetchUpdatedData);

    // Set theme default
    const { darkMode: defaultMode } = this;
    const defaultValue = defaultMode || 0;
    const darkMode = localStorage.getItem('theme-dark');
    const isDarkMode = parseInt(darkMode || defaultValue, 10);
    this.toggleDarkMode(isDarkMode);

    // Set sidebar state default
    const { compactSidebar: defaultSidebar } = this;
    const defaultSidebarValue = defaultSidebar || 0;
    const compactSidebar = localStorage.getItem('compact-sidebar');
    const isCompactSidebar = parseInt(
      compactSidebar || defaultSidebarValue,
      10,
    );
    this.toggleSidebar(isCompactSidebar);

    this.oxygenMode = Boolean(
      this.$cookies.isKey('x-source')
      && this.$cookies.get('x-source') === 'oxygen',
    );


    let authToken = localStorage.getItem('WWW-Authenticate');

    window.addEventListener('navigateToMain', () => this.$router.push('/'));
    window.addEventListener('navigateToMCS', () => this.$router.push('/multichannel-sender'));

    if (authToken) {
      this.loading = true;
      this.fetchData();
    }

    // if authToken is not found, retry after 1 sec
    if (!authToken) {
      setTimeout(() => {
        authToken = localStorage.getItem('WWW-Authenticate');

        if (authToken) {
          this.fetchData();
        } else {
          console.log("Token not found."); // eslint-disable-line
        }
      }, 1000);
    }

    window.addEventListener('userUpdated', () => {
      const user = localStorage.getItem('CPV3_User');

      if (user) {
        try {
          this.userData = JSON.parse(user) || {};
          this.userProfile = JSON.parse(user) || {};

          this.name = this.userData.Firstname || this.userData.Lastname
            ? `${this.userData.Firstname || ''} ${this.userData.Lastname || ''
            }`
            : this.userData.Login;

          this.isAdmin = this.userData.AccessLevel === 'A';
        } catch (e) {
          // do nothing
        }
      }
    });

    window.addEventListener('account', (e) => {
      this.openSettings(e.type);
    });

    window.addEventListener('profile', (e) => {
      this.openSettings(e.type);
    });

    window.addEventListener('clearNav', () => {
      _.forEach(this.$refs, (el, key) => {
        if (this.$refs[key] && this.$refs[key].$el) {
          this.$refs[key].$el.className = 'relative flex items-center h-full px-6 py-3 text-grey-dark no-underline text-sm border-r router-link-exact-active';
        }
      });
    });

    window.addEventListener('updateBalance', () => {
      this.getBalance();
    });
  },

  methods: {
    ...mapMutations({
      setEnforce2FA: 'account/SET_ENFORCE_2FA',
    }),

    ...mapActions({
      updateTwoFA: 'account/updateTwoFA',
      enableTwoFa: 'account/enableTwoFa',
      getTwoFaQrCode: 'account/getTwoFaQrCode',
      getTwoFaOtpCode: 'account/getTwoFaOtpCode',
      getTimezones: 'common/getTimezones',
      getBalance: 'common/getBalance',
      getAuthUser: 'common/getAuthUser',
      getWhitelistedCountries: 'common/getWhitelistedCountries',
      getUserCountry: 'common/getUserCountry',
      updateCachedUser: 'common/updateCachedUser',
      enforce2FA: 'account/getEnforce2FA',
      getUserCacheData: 'common/getUserCacheData',
      getMessageBalance: 'common/getMessageBalance',
      getWhatsAppChannels: 'common/getWhatsAppChannels',
      getWhatsAppTemplates: 'common/getWhatsAppTemplates',
    }),

    ...mapMutations({
      setEnforce2FA: 'account/SET_ENFORCE_2FA',
    }),

    isPackageLimit(moduleId) {
      return handlePackageLimit(moduleId);
    },

    getIcon(appName) {
      const icons = {
        SMS: 'icon-novo-message',
        'Sender ID': 'icon-novo-evaluate',
        'Chat apps': 'icon-novo-whatsapp',
        'Video interaction': 'icon-novo-video',
        Voice: 'icon-novo-phone',
      };

      return icons[appName];
    },

    getIconLabel(appName) {
      const self = this;
      const label = {
        SMS: self.$t('sidebar_menu_parent_short.SMS'),
        'Sender ID': self.$t('sidebar_menu_parent_short.SenderId'),
        'Chat apps': self.$t('sidebar_menu_parent_short.MSG'),
        'Video interaction': self.$t('sidebar_menu_parent_short.Video'),
        Voice: self.$t('sidebar_menu_parent_short.Voice'),
      };

      return label[appName];
    },

    // cp-sms-engage
    isPackageLimit_v2(appname) {
      if (appname.toUpperCase() === 'SMS') return this.isPackageLimit('cp-sms');
      if (appname.toUpperCase() === 'SENDER ID') return this.isPackageLimit('cp-sender-id') && this.hasSenderIdAccess;
      if (appname.toUpperCase() === 'CHAT APPS') return this.isPackageLimit('cp-chatapps');
      if (appname.toUpperCase() === 'VIDEO INTERACTION') return this.isPackageLimit('cp-video');
      if (appname.toUpperCase() === 'VOICE') return this.isPackageLimit('cp-voice');
      return true;
    },

    async getUserBalance(user) {
      this.isShowBalance = Boolean(user.Flag_ShowBalance) && user.AccessLevel === 'A';
      // get balance
      if (this.isShowBalance) {
        try {
          const v = await this.getBalance();
          localStorage.setItem('balance_curr', v.Currency);
        } catch (err) {
          this.$showError(this, err);
          localStorage.removeItem('balance_curr');
          throw err;
        }
      }
    },

    async fetchUserCountry() {
      try {
        const uc = localStorage.getItem('user_country') || '';

        if (!uc) {
          const v = await this.getUserCountry();
          localStorage.setItem('user_country', v);
        }
      } catch (e) {
        localStorage.removeItem('user_country');
      }
    },

    expandAppDrawer() {
      const activeAppMenu = Object.keys(this.appRoutes).find(appName => window.location.pathname.includes(this.appRoutes[appName].url || -1));
      this.openedAppMenu = activeAppMenu || '';
    },

    singleSpaRouteListener(evt) {
      this.activeRoute = window.location.pathname;
      const user = localStorage.getItem('CPV3_User');
      const currPath = evt.target.location.pathname;

      if (user) {
        try {
          const u = JSON.parse(user);
          if (u) {
            this.prevUrl = currPath;
          }
        } catch (e) {
          // do nothing
        }
      }
    },

    addActiveClass(
      assignedPath = '/',
      className = 'nv-sidebar__link--active shadow',
    ) {
      // Remove first and last slashes
      const path = assignedPath.replace(/^\/|\/$/g, '');

      const activeRoute = this.activeRoute || window.location.pathname;
      let activePath = activeRoute.replace(/^\/|\/$/g, '');

      if (activePath.includes('support/')) {
        activePath = 'support';
      }

      if (activePath.includes('automation/')) {
        activePath = 'automation';
      }

      return path === activePath ? className : '';
    },

    toggleSidebar(isCompact = false) {
      this.compactSidebar = isCompact;

      const rootElem = document.documentElement;
      const COMPACT_SIDEBAR_CLASS = 'compact-sidebar';

      // Toggle sidebar class in body
      if (isCompact) {
        rootElem.setAttribute('data-sidebar', COMPACT_SIDEBAR_CLASS);
      } else {
        rootElem.setAttribute('data-sidebar', '');
      }

      // Update localStorage theme value to current sidebar theme
      localStorage.setItem('compact-sidebar', isCompact ? 1 : 0);
    },

    toggleDarkMode(isDark = false) {
      this.darkMode = isDark;

      const rootElem = document.documentElement;
      const DARK_MODE_CLASS_NAME = 'dark-mode';

      // Toggle dark-mode class in body
      if (isDark) {
        rootElem.setAttribute('data-theme', DARK_MODE_CLASS_NAME);
      } else {
        rootElem.setAttribute('data-theme', '');
      }

      // Update localStorage theme value to current theme value
      localStorage.setItem('theme-dark', isDark ? 1 : 0);
    },

    toggleOxygenTheme() {
      this.oxygenMode = !this.oxygenMode;
      const analyticsEvent = 'SWITCH_TO_OLD_THEME';
      if (window.analytics && Object.keys(this.userData).length) {
        window.analytics.track(
          analyticsEvent,
          {
            Email: this.userData.Email,
          },
          () => {
            window.location.reload(true);
          },
        );
      } else {
        window.location.reload(true);
      }
    },

    disableTwoAuth() {
      return !this.isCountryWhiteListed;
    },

    checkShowSuggestEnableTwoFa() {
      const hideSuggestEnableTwoFa = localStorage.getItem(
        'hideSuggestEnableTwoFa',
      );
      const sessionHideSuggestEnableTwoFa = sessionStorage.getItem(
        'sessionHideSuggestEnableTwoFa',
      );

      try {
        const cpv3User = localStorage.getItem('CPV3_User') || {};
        const user = JSON.parse(cpv3User);

        if (
          !hideSuggestEnableTwoFa
          && !sessionHideSuggestEnableTwoFa
          && !user.TwoFASetting.length
          && user.Needs2FA
          && !user.SSOEnabled
        ) {
          this.modalScreen = 'suggest-enable';
          this.showProfileDialog = true;
          this.isModalShow = true;
        }

        sessionStorage.setItem('sessionHideSuggestEnableTwoFa', true);
      } catch (e) {
        //  do nothing
      }
    },

    setupTwoFa() {
      localStorage.setItem('hideSuggestEnableTwoFa', true);
      this.modalScreen = null;
      this.tab = 'two-factor';
    },

    submit(p) {
      this.verificationLoading = true;
      const payload = { ...p, remember: false };
      // const { type } = payload;
      // const authName = this.getTwoFaAuthName(type);
      this.enableTwoFa(payload)
        .then(() => {
          // remove success message OMG-839
          // this.$message({
          //   message: `Enabled ${authName} two-factor auth`,
          //   type: 'success',
          // });
          this.modalScreen = null;
          this.validationStep = 1;
          this.updateTwoFA();

          // Stop showing suggest 2FA enable once user enables an 2FA
          localStorage.setItem('hideSuggestEnableTwoFa', true);
          this.setEnforce2FA(false);
        })
        .catch((err) => {
          // this.$message.error(err || this.$t('errors.default'));
          this.$showError(this, err);
        })
        .finally(() => {
          this.verificationLoading = false;
        });
    },

    getTwoFaAuthName(authCode) {
      let name = '';
      switch (authCode) {
      case 'AUTHENTICATOR_APP':
        name = 'Authenticator app';
        break;
      case 'SMS_OTP':
        name = 'Sms';
        break;
      default:
        break;
      }
      return name;
    },

    backModalStep() {
      if (!this.verificationLoading) {
        if (this.validationStep === 1) {
          this.backModal();
        } else {
          this.updateValidationStep(this.validationStep - 1);
        }
      }
    },

    getStepLabel(authName) {
      return this.stepsHeader[authName][this.validationStep - 1];
    },

    updateValidationStep(n) {
      this.validationStep = n;
    },

    backModal() {
      this.modalScreen = null;
    },

    closeModal() {
      this.resetForm = false;
      this.validationStep = 1;

      if (
        this.modalScreen
        && this.modalScreen.match(/suggest-enable/)
        && this.suggestEnableAuth
      ) {
        localStorage.setItem('hideSuggestEnableTwoFa', true);
      }

      this.modalScreen = null;
    },

    enableForm(code) {
      this.modalScreen = code;
    },

    showMessage(message, type) {
      this.$message({
        showClose: true,
        message,
        type,
      });
    },

    getUserCountryCode() {
      const uc = localStorage.getItem('user_country');

      const tzname = this.$jstz2.determine().name();
      const tzCountry = this.timezones.find(tz => tz.TimeZoneName === tzname);

      if (uc) {
        return uc.split(';')[1];
      }

      if (tzCountry && tzCountry.Country) {
        return tzCountry.Country;
      }

      return '';
    },

    isCountryWhiteListed(userCountry) {
      return this.whiteListedCountries.some(v => v.Country === userCountry);
    },

    async fetchUpdatedData() {
      await this.updateCachedUser();
      this.fetchData();
    },

    fetchData() {
      const compactSidebar = localStorage.getItem('compact-sidebar');
      const isCompactSidebar = parseInt(
        compactSidebar || 0,
        10,
      );
      this.getAuthUser()
        .then(({ data }) => {
          if (!data) {
            throw new Error('No data.');
          }

          this.getUserCacheData({ userId: data.UserId })
            .then(async ({ cacheData }) => {
              const userCacheData = JSON.parse(cacheData);
              const { AccountConfig: accountConfig } = userCacheData;
              const twoFaData = await this.updateTwoFA();

              if (accountConfig) {
                const accountSetting = JSON.parse(accountConfig);
                this.enforce2fa = accountSetting.Enforce2FA;
                const isImpersonation = Boolean(
                  localStorage.getItem('cpv3Impersonate'),
                );

                // Check if enforce 2fa config has been enabled and the user has not enabled 2fa
                if (
                  !isImpersonation
                  && this.enforce2fa
                  && twoFaData.filter(d => d.enabled).length <= 0
                ) {
                  this.setEnforce2FA(true);
                  this.show2faSetting = true;
                  this.openSettings('two-factor');
                }
              }
            })
            .catch((err) => {
              this.$showError(this, err);
            });

          // save to local storage
          localStorage.setItem('CPV3_User', JSON.stringify(data));

          // Trigger analytics event
          /* global analytics */
          analytics.identify(data.UserId, {
            Email: data.Email,
            Login: data.Login,
            AccountId: data.AccountId,
          });

          this.userData = data;

          // trigger event to inform dashboard of userChanges
          const event = new Event('userUpdated');
          window.dispatchEvent(event);

          const name = data.Firstname || data.Lastname
            ? `${data.Firstname || ''} ${data.Lastname || ''}`
            : data.Login;

          this.name = name;
          this.needMigrationFromV2 = data.NeedMigrationFromV2;
          this.userId = data.UserId;
          this.login = data.Login;
          this.authUserMangement = data.AccessLevel === 'A'
            || (data.AccessLevel === 'U'
              && data.Roles.some(v => v.match(/UserManagement/i)));
          this.hasPaymentAccess = data.Roles.includes('Payment_v2') && data.IsPostPaid !== 1;
          const hasSenderIdAccess = data.Features && data.Features.senderId;
          this.hasSenderIdAccess = hasSenderIdAccess;

          if (hasSenderIdAccess) {
            this.openedAppMenu = 'SMS';
          }

          if (this.isNewWARegistered()) {
            this.getWhatsAppChannels()
              .then((ch = []) => {
                const waChls = ch.filter(v => v.type === 'WhatsApp' && v.status === 'Active');
                if (waChls.length === 1) {
                  return this.getWhatsAppTemplates({ channelId: waChls[0].id });
                }
                return Promise.resolve(undefined);
              }).then((t) => {
                if (Array.isArray(t) && t.length < 1) {
                  this.isNewToWhatsApp = true;
                }

                this.hasDoneWARequest = true;
                this.$nextTick(() => {
                  this.waPopoverVisible = !(parseInt(localStorage.getItem('wa_popover_hidden'), 10) || 0) && !isCompactSidebar && window.location.pathname !== '/chat/register';
                });
              }).catch((err) => {
                this.$showError(this, err);
              });
          } else if (this.showPrepaidCA()) {
            this.hasDoneWARequest = true;

            this.$nextTick(() => {
              this.waPopoverVisible = !(parseInt(localStorage.getItem('wa_popover_hidden'), 10) || 0) && !isCompactSidebar && window.location.pathname !== '/chat/register';
            });
          }

          // get all the common data
          Promise.all([
            this.getUserBalance(data),
            this.getWhitelistedCountries(),
            this.getTimezones(),
          ]).then(async () => {
            const {
              // SiteVersion_MigrationEnabled: migrationEnabled,
              // eslint-disable-next-line no-unused-vars
              SiteVersion_Current: currentVersion,
              PasswordExpiresAt: expiring,
              Username: username,
              SSOEnabled: isSSOEnabled,
            } = data;

            this.userCountryCode = this.getUserCountryCode();
            this.isUserCountryWhiteListed = this.isCountryWhiteListed(this.userCountryCode);

            // Free Credits should be displayed first in first login
            // other modal will follow in the next login

            // Free Credits
            const isShowOtp = Boolean(localStorage.getItem('showFCO'));

            // check if user has freecredits offer
            // check if user is account managed or not
            // check if user's country is in the whitelist
            this.hasFreeCredits = data
              && data.FreeCreditsOffer
              && data.CustomerType !== 'E'
              && this.isUserCountryWhiteListed;

            // this.hasFreeCredits
            // show freecredits otp
            if (this.hasFreeCredits && !isShowOtp) {
              this.showOTP = true;
              this.isModalShow = true;
              localStorage.setItem('showFCO', true);
            }

            // Password expiring
            this.passwordResetSkipped = localStorage.getItem('passwordResetSkipped');


            if (!this.isImpersonation && expiring) {
              // Update UI Flow for Password Expiring

              const expiry = Moment(expiring);
              const now = Moment();
              const d = expiry.diff(now, 'days', true);

              if (d <= 0 && !isSSOEnabled) {
                this.$recaptchaLoaded().then(() => this.$recaptcha('forgot_password'))
                  .then(token => http.v1.post('auth/forgot-password', {
                    email: username,
                    gToken: token,
                    version: 'v3',
                    isPasswordExpired: true,
                  }, {
                    headers: {
                      'api-test': 'invalidate-reCaptcha',
                    },
                  })).then((result) => {
                    const { resetLink } = result.data;
                    http.v1.post('auth/logout').then(() => {
                      this.cleanup();
                      if (resetLink) {
                        window.location = `${resetLink}?feedback=password-expire`;
                      }
                    });
                  })
                  .catch((err) => {
                    this.$showError(this, err, { useMessage: this.$t('errors.invalid'), reloadUrl: '/login/forgot-password' });
                  });
              } else if (d <= 14) {
                this.$message({
                  showClose: false,
                  message: this.$t('warning.password_expiring', { value: Math.round(d, -1) }),
                  type: 'error',
                  duration: 8888,
                });
                this.openSettings('change-password');
              }
            }

            // 2fa suggestion
            // if (!this.disableTwoAuth() && !this.showPasswordExpiring && !this.isModalShow) {
            //   this.checkShowSuggestEnableTwoFa();
            // } -- MAP-1479 hide 2FA
          })
            .catch((err) => {
              // this.$message.error(e.message || 'Failed to load common data.');
              this.$showError(this, err);
            });
        })
        .catch((err) => {
          if (err && err.error === 'Unauthorized') {
            this.cleanup();
            this.$showError(this, err);
          } else {
            this.$showError(this, err);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    cleanup() {
      this.isModalShow = false;
      localStorage.removeItem(this.authTokenKey);
      localStorage.removeItem('cpv3Impersonate');
      localStorage.removeItem('CPV3_User');
      localStorage.removeItem('passwordResetSkipped');
      localStorage.removeItem('user_country');
      sessionStorage.removeItem('sessionHideSuggestEnableTwoFa');

      // destroy telemetry service and client
      const { teardownTelemetry } = this.$telemetry;

      teardownTelemetry();
    },

    logout() {
      http.v1
        .post('auth/logout')
        .then(() => {
          // remove userData
          this.cleanup();

          window.location = '/login';
        })
        .catch((err) => {
          // if (window.Bugsnag) {
          //   window.Bugsnag.notify(err);
          // }
          if (
            err.statusCode === 401
            || (err.error && err.error.match(/Unauthorized/))
          ) {
            this.cleanup();
            // this.showMessage(this.$t('errors.session_expired'), 'error');
            this.$showError(this, err, {
              useMessage: this.$t('errors.session_expired'),
            });
            window.location = '/login';
          } else {
            this.$showError(this, err);
          }
        });
    },

    openSettings(tab) {
      this.isFetching = true;
      let user = {};

      try {
        user = localStorage.getItem('CPV3_User') || {};
        user = JSON.parse(user);
      } catch (e) {
        // do nothing
      }
      this.userData = user;
      this.userProfile = Object.assign({}, user);

      this.name = this.userProfile.Firstname || this.userProfile.Lastname
        ? `${this.userProfile.Firstname || ''} ${this.userProfile.Lastname || ''
        }`
        : this.userProfile.Login;
      this.isAdmin = this.userProfile.AccessLevel === 'A';

      this.isFetching = false;
      let tabStr = tab;
      if (!tab) {
        tabStr = 'account';
      }

      this.showProfile(tabStr);
    },

    openUserManagement() {
      this.$router.push({ path: '/user-management' });
    },

    openMultichannel() {
      this.$router.push({ path: '/multichannel-sender' });
    },

    handleCommand(command) {
      switch (command) {
      case 'logout':
        this.logout();
        break;
      case 'settings':
        this.openSettings();
        break;
      case 'usermanagement':
        this.openUserManagement();
        break;
      case 'multichannelsender':
        this.openMultichannel();
        break;
      default:
        break;
      }
    },

    showProfile(tab) {
      let t = tab;
      if (tab === 'account' && !this.isAdmin) {
        t = 'profile';
      }

      this.tab = t;
      this.showProfileDialog = true;
    },

    closePasswordExpiring() {
      localStorage.setItem('passwordResetSkipped', true);
      this.showPasswordExpiring = false;
    },

    closeOTPModal() {
      this.showOTP = false;
    },

    showOTPModal() {
      this.showOTP = true;
    },

    updateUserAndBalance() {
      // update user
      this.getAuthUser()
        .then(({ data }) => {
          if (!data) {
            throw new Error('No data.');
          }

          this.userData = { ...data };
          this.userProfile = { ...data };

          this.hasFreeCredits = data
            && data.FreeCreditsOffer
            && data.CustomerType !== 'E'
            && this.isUserCountryWhiteListed;

          // update local storage
          localStorage.setItem('CPV3_User', JSON.stringify(data));

          this.getUserBalance(this.userData);
        })
        .catch((err) => {
          this.$showError(this, err);
        });
    },

    showBalanceAlert() {
      const {
        CustomerType,
        IsTrialAccount,
        IsPostPaid,
        ShowBalanceAlert,
        LowBalanceThreshold,
        LowBalanceAlertEnabled,
      } = this.userData;

      if (
        this.balance
        && !!LowBalanceAlertEnabled
        && !_.isNull(LowBalanceThreshold)
        && !!ShowBalanceAlert
        && !IsTrialAccount
        && !IsPostPaid
        && CustomerType === 'E'
      ) {
        return true;
      }

      return false;
    },

    isNewWARegistered() {
      const {
        BetaFeatures,
        // eslint-disable-next-line camelcase
        Product_CA: productCA,
      } = this.userData;

      // Included in beta feature but user hasn't activated it
      // so show coachmark
      return BetaFeatures && BetaFeatures.includes('CA-Prepaid') && productCA;
    },
    handleWhatsNew() {
      if (this.showPrepaidCA() || (this.isNewWARegistered() && this.isNewToWhatsApp)) {
        this.waPopoverVisible = true;
      }
    },

    showPrepaidCA() {
      const {
        BetaFeatures,
        // eslint-disable-next-line camelcase
        Product_CA: productCA,
      } = this.userData;

      // Included in beta feature but user hasn't activated it
      // so show coachmark
      return BetaFeatures && BetaFeatures.includes('CA-Prepaid') && !productCA;
    },

    hasAutomation() {
      const { Product_AT: hasAutomation } = this.userData;

      return Boolean(hasAutomation);
    },

    // eslint-disable-next-line consistent-return
    beforeDialogClose(done) {
      // Check if enforce 2fa config has been enabled and the user has not enabled 2fa
      // Disallow closing of the modal
      if (
        this.show2faSetting
        && this.enforce2fa
        && this.getTwoFA.filter(d => d.enabled).length <= 0
      ) {
        this.$message.warning('Your admin has enforced you to setup 2FA');
        return false;
      }
      done();
    },

    // eslint-disable-next-line no-unused-vars
    closeWAPopover(isNewRegistered = false) {
      this.waPopoverVisible = false;


      const isNewFeaturesDialogHidden = Number(localStorage.getItem('wa_dialog_hidden'));

      if (!isNewFeaturesDialogHidden) {
        this.waDialogVisible = true;
      } else {
        localStorage.setItem('wa_popover_hidden', 1);
      }
    },

    openWAPopover() {
      this.waPopoverVisible = true;
    },

    closeWAInfoDialog({ checked }) {
      this.waDialogVisible = false;

      if (checked) {
        localStorage.setItem('wa_dialog_hidden', 1);
      }
    },
  },
};
</script>

<style lang="scss">
.disabled-link {
  pointer-events: auto !important;
  cursor: not-allowed !important;
}

.nv-topbar {
  height: var(--navbar-height);
  background: var(--background);
  border-bottom: 1px solid var(--background-light);
}

.nv-sidebar--compact .nv-sidebar__inner {
  padding: 12px 6px;
}

.nv-topbar__title {
  color: var(--text-color);
}

/* Sidebar */
.nv-sidebar {
  $this: &;
  width: var(--sidebar-width);
  top: var(--navbar-height);
  background-color: var(--background);
  transition: width var(--ease);
  border-right: solid 1px var(--background-light);

  &--compact {
    width: var(--sidebar-shrink-width);

    #{$this}__link:not(#{$this}__link--text) {
      overflow: hidden;
      width: 34px;
      height: 34px;
    }

    #{$this}__label {
      display: none;
    }

    #{$this}__logo {
      width: 50px;
      margin: auto;
    }

    border-color: var(--background-light);
  }

  &__inner {
    padding: 12px 8px;
    scrollbar-width: thin;
    overflow-y: auto;
    scrollbar-color: var(--text-color);
    height: calc(100% - 108px);
  }
}

.nv-sidebar__inner::-webkit-scrollbar {
  display: block;
}

.nv-sidebar--compact .nv-sidebar__expand-button {
  width: 47px;
}

.nv-sidebar__expand-button--inner {
  display: flex;
  align-items: center;
  border-top: 1px solid var(--background-light);
  border-bottom: 1px solid var(--background-light);
  height: 36px;
  color: var(--text-color);
}

.nv-sidebar__expand-button {
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 219px;
  padding: 12px;
  background-color: var(--background);
  cursor: pointer;

  transition: color var(--ease), background-color var(--ease),
    opacity var(--ease), border var(--ease);
}

/* Logo */
.nv-sidebar__logo {
  transition: color var(--ease);
}

/* Nav */
.nv-sidebar__nav {
  padding: 0;
}

.nv-sidebar__icon {
  font-size: 1.2rem;
  vertical-align: middle;
}

.nv-sidebar__label,
.nv-sidebar__label--inner {
  margin-left: 0.6rem;
  white-space: nowrap;
  font-size: 14px;
}

/* Link */
.nv-sidebar__link {
  padding: 8px;
  margin-bottom: 8px;
  color: var(--text-color);
  text-decoration: none;
  display: flex;
  align-items: center;
  border-radius: 6px;
  overflow: hidden;
  font-size: 14px;

  transition: color var(--ease), background-color var(--ease), width var(--ease),
    height var(--ease);

  &--text {
    font-size: 14px;
    color: var(--text-color);
    text-decoration: none;
    display: block;
    text-transform: uppercase;
    text-align: center;
    font-weight: 500;
  }

  &:focus {
    outline: var(--blue) auto 1px !important;
  }
}

.nv-sidebar__link--active {
  background-color: var(--sidebar-highlight);

  i,
  span {
    font-weight: 600;
  }
}

.nv-sidebar__link[href=""] {
  opacity: 0.3;
  pointer-events: none;
}

.nv-sidebar__link:not(.nv-sidebar__link--active):hover {
  background-color: var(--sidebar-highlight);
}

.nv-sidebar__link--inner {
  margin-bottom: 8px;
  color: var(--text-color);
  position: relative;
  box-shadow: none !important;

  transition: color var(--ease), background-color var(--ease);
}

.nv-sidebar__link--inner:hover {
  background-color: var(--sidebar-highlight);
}

.nv-sidebar__link--inner:before {
  content: "";
  width: 2px;
  height: 15px;
  position: absolute;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
  // background-color: currentColor;
  opacity: 0;

  transition: opacity var(--ease);
}

.nv-sidebar__link.nv-sidebar__link--inner:hover,
.nv-sidebar__link--inner.nv-sidebar__link--active {
  background-color: var(--sidebar-highlight);
}

.nv-sidebar__link--inner.nv-sidebar__link--active {
  background-color: var(--sidebar-highlight);
}

.nv-sidebar__link--inner.nv-sidebar__link--active.nv-sidebar__link--inner:before {
  opacity: 1;
}

/* Sub nav */
.nv-sidebar__subnav {
  padding-top: 8px;
  padding-bottom: 8px;
  margin-bottom: 8px;
  overflow: hidden;
  border-bottom: 1px solid var(--background-light);
  border-top: 1px solid var(--background-light);

  &.dropdown {
    margin: 0 15px;

    .nv-sidebar__text {
      font-size: 14px;
      display: flex;
      align-items: center;
    }
  }
}

.nv-dropdown__options {
  padding-left: 8px;
  margin-left: 16px;
  border-left: 1px solid var(--background-light);
}

/* Group */
.nv-sidebar__group {
  /* height: 0; */
  padding: 0;
  list-style: none;

  transition: height var(--ease);
}

.nv-sidebar__group-item {
  position: relative;
  color: var(--text-color);
  transition: background-color var(--ease);
}

/* Right icon */
.nv-sidebar__right-icon {
  padding: 10px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%) rotate(0deg);
  transition: transform var(--ease);
}

.is-open .nv-sidebar__right-icon {
  transform: translateY(-50%) rotate(180deg);
}

/* Text */
.nv-sidebar__text {
  color: var(--text-color);
  display: flex;
  padding: 8px;
  white-space: nowrap;
  transition: color var(--ease), background-color var(--ease);
}

.nv-sidebar__text--hover-highlight {
  cursor: pointer;
}

/* Widgets */
.nv-sidebar__widget {
  position: fixed;
  top: 0;
  right: 0;
  height: var(--sidebar-shrink-width);
}

.nv-balance-alert-top {
  max-width: 687px;
}

/* Subapps container */
.nv-sidebar__subapps {
  margin: 10px 0;
}

/* Subapps expandable nav */
.nv-sidebar__expandable-nav {
  text-align: left;
  // width: 0;
  // height: 100vh;
  // position: absolute;
  // top: 0;
  // left: 100%;
  background-color: var(--background);
  // border-right: solid 1px var(--background-highlight);
  // box-shadow: -1px 0 1px 0px var(--gray-lightest) inset;
  overflow: hidden;
  white-space: nowrap;
  // opacity: 0;
  transition: width var(--ease), opacity var(--ease);
  color: var(--text-color);

  i,
  span {
    color: var(--text-color) !important;
  }

  .normal-case h1 {
    padding-bottom: 8px;
    margin-bottom: 8px;
    border-bottom: 1px solid var(--background-light);
  }
}

/* Subapps expandable trigger button */
.nv-sidebar__expand-trigger {
  // &:hover {
  //   & .nv-sidebar__expandable-nav {
  //     width: 220px;
  //     opacity: 1;
  //   }
  // }
}

.nv-sidebar__expandable-list {
  position: relative;
}

.nv-sidebar__expandable-item {
  color: var(--text-color);
  padding: 8px;
  display: block;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color var(--ease), color var(--ease);

  &:hover {
    background-color: var(--sidebar-highlight);
  }

  &--active {
    background-color: var(--sidebar-highlight);
    font-weight: 600 !important;
  }

  &:focus {
    outline: var(--blue) auto 1px !important;
  }
}

.nv-sidebar__expandable-list {
  padding: 2px;
}

.nv-sidebar-popover {
  background-color: var(--background) !important;
  border-color: var(--background-highlight) !important;

  .popper__arrow,
  .popper__arrow:after {
    border-right-color: var(--background) !important;
  }
}

.password-expiring {
  background: gray;
  opacity: 1;
}

.navbar-container {
  z-index: 3;
}

.active-main--nav {
  color: #22292f;
  background: #eff8ff;
  position: relative;
}

.nav-icon {
  filter: grayscale(100%);
  opacity: 0.5;
}

.active-main--nav .nav-icon {
  filter: none;
  opacity: 1;
}

.active-main--nav:before {
  content: "";
  width: 100%;
  height: 3px;
  background: #3490dc;
  position: absolute;
  bottom: 0;
  left: 0;
}

.otp-modal {
  // background-color: rgba(255, 255, 255, 0.8) !important;
  background: rgba(0, 0, 0, 0.5) !important;
}

.el-dialog.dark-theme {
  background-color: #181619 !important;
}

.el-dialog.dark-theme .el-dialog__body,
.el-dialog.new-theme .el-dialog__title {
  color: var(--text-color) !important;
}

.el-dialog.new-theme .el-dialog__headerbtn .el-icon-close {
  font-size: 24px !important;
  color: var(--text-color) !important;
}

.impersonation-text-anim {
  animation: blinker 2s infinite;
}

@keyframes blinker {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0.3;
  }

  to {
    opacity: 1;
  }
}
</style>

import axios from 'axios';
import { Promise } from 'es6-promise';
import http from '../../../../utils/http';

export default {

  async getCountries({ commit }) {
    try {
      const { data } = await http.v1.get('countries');
      commit('SET_COUNTRIES', data[0]);
      return data[0];
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  async getTimezones({ commit }) {
    try {
      const { data } = await http.v1.get('timezones');
      commit('SET_TIMEZONES', data[0]);
      return data[0];
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  setTimezones({ commit }, payload) {
    return new Promise((resolve) => {
      commit('SET_TIMEZONES', payload);
      resolve(payload);
    });
  },

  async getSubAccounts({ commit }, payload) {
    try {
      const { data } = await http.v1.get(`accounts/${payload.AccountUid}/sub-accounts`);
      commit('SET_SUB_ACCOUNTS', data[0]);
      return data[0];
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  async getAuthUser({ commit }) {
    try {
      const { data } = await http.v1.get('auth/user?rolesVersion=2');
      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  async getBalance({ commit }) {
    try {
      const { data } = await http.v1.get('balance');
      commit('SET_BALANCE', data);
      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  // verify otp
  async getWhitelistedCountries({ commit }, payload) {
    try {
      const { data } = await http.v1.get('onboarding/countries', payload);

      commit('SET_WHITELISTED_COUNTRIES', data);
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      throw new Error(err.message);
    }
  },

  async getUserCountry({ commit }) {
    try {
      const { data } = await axios.get('https://ip2c.org/s');

      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      throw new Error(err.message);
    }
  },

  async updateCachedUser({ commit }) {
    return new Promise((resolve, reject) => {
      http.v1.post('auth/cached-user/update', {})
        .then(({ data }) => {
          resolve({ data });
        })
        .catch((err) => {
          commit('API_ERROR', err, { root: true });
          reject(err);
        });
    });
  },

  async getUserCacheData({ commit }, payload) {
    try {
      const { userId } = payload;
      const { data } = await http.v2.get(`caches/user?id=${userId}`);
      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  async getMessageBalance({ commit }, payload) {
    try {
      const { data } = await http.v2.get(`accounts/${payload.accountUid}/message-balance?tzOffset=${payload.timeZoneOffset}`);
      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  async getWhatsAppChannels({ commit }) {
    try {
      const { data } = await http.v2.get('chats/channels/whatsapp');
      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  async getWhatsAppTemplates({ commit }, payload) {
    try {
      const { data } = await http.v2.get(`chats/templates?category=&language=&status=&channelId=${payload.channelId}`);
      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { parsePhoneNumber } from 'awesome-phonenumber';
import { VueTelInput } from 'vue-tel-input';

import CodeInput from './CodeInput.vue';

export default {
  name: 'AuthSms',

  components: {
    CodeInput,
    VueTelInput,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    step: {
      type: Number,
      default: 1,
    },
    generateOtp: {
      type: Function,
      default: () => {},
    },
  },

  data() {
    const validatePhone = (rule, value, callback) => {
      if (this.validPhone && value.trim().length) {
        callback();
      } else {
        callback(new Error(this.$t('validations.valid', { value: this.$t('fields.phone_number') })));
      }
    };

    const self = this;

    return {
      otpLoading: false,
      timer: 120, // seconds
      timerId: null,
      counter: 0,
      resendable: true,

      validPhone: false,

      form: {
        uid: '',
        token: '',
        phoneNumber: '',
      },

      formRules: {
        token: [
          { required: true, message: self.$t('validations.valid', { value: this.$t('fields.code') }), trigger: 'blur' },
        ],
        phoneNumber: [
          {
            validator: validatePhone,
            trigger: 'blur',
          },
        ],
      },
    };
  },

  watch: {
    step(value) {
      this.form.token = '';

      // Clear timer from event loop queue
      if (this.timerId && value !== 2) {
        clearTimeout(this.timerId);
      }
    },
  },

  beforeDestroy() {
    if (this.timerId) {
      clearTimeout(this.timerId);
    }
  },

  methods: {
    changeNumber() {
      this.$emit('update-step', 1);
    },

    handlePhoneValidate(n, validator) {
      this.validPhone = validator.valid;
      this.$refs.form.validateField('phoneNumber');
    },

    handleOnBlurPhone() {
      this.$refs.form.validateField('phoneNumber');
    },

    cancel() {
      this.$emit('update-step', 1);
      this.$emit('back');
    },

    submit() {
      if (this.$refs.form) {
        this.$refs.form.validate((valid) => {
          if (valid) {
            const payload = { type: 'SMS_OTP', ...this.form };
            this.$emit('submit', payload);
          } else {
            return false;
          }
          return false;
        });
      }
    },

    countdown() {
      if (this.counter > 0) {
        this.timerId = setTimeout(() => {
          this.counter -= 1;
          this.countdown();
        }, 1000);
      } else {
        this.resendable = true;
      }
    },

    resendCode() {
      if (this.resendable) {
        this.counter = this.timer;
        this.countdown();
        this.sendCode();
        this.resendable = false;
      }
    },

    sendCode() {
      this.resendable = true;
      this.otpLoading = true;

      const pn = parsePhoneNumber(this.form.phoneNumber);
      const payload = {
        ...this.form,
        country: pn.regionCode,
      };

      // execute first recaptcha
      this.$recaptchaLoaded()
        .then(() => this.$recaptcha('generate_2fa_otp'))
        .then((token) => {
          payload.gToken = token;

          return this.generateOtp(payload);
        }).then((res) => {
          this.form.uid = res.uid;
          this.$emit('update-step', 2);
        })
        .catch((err) => {
          this.qrCode = '';
          // this.$message.error('Failed to send OTP code');
          this.$showError(this, err);
        })
        .finally(() => {
          this.otpLoading = false;
        });
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment';
import { mapActions } from 'vuex';
import languages from '../../../locale/lang/languages.json';
import { handlePackageLimit } from '../../common/handle-package-limit';

export default {
  props: {
    userData: {
      type: Object,
      default: () => {},
    },

    balance: {
      type: Object,
      default: () => {},
    },
    enableManageUser: {
      type: Boolean,
      default: false,
    },
    isShowOtp: {
      type: Boolean,
      default: false,
    },

    hasFreeCredits: {
      type: Boolean,
      default: false,
    },

    isShowBalance: {
      type: Boolean,
      default: false,
    },

    darkMode: {
      type: Boolean,
      default: false,
    },

    isImpersonation: {
      type: Boolean,
      default: false,
    },

    oxygenMode: {
      type: Boolean,
      default: false,
    },

    isShowOxygenToggleBtn: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    let hasSubscription;
    try {
      const user = JSON.parse(localStorage.getItem('CPV3_User'));
      hasSubscription = user.Subscription.Status;
    } catch (e) {
      // do nothing
    }

    return {
      isOpen: false,
      selectedDropdown: '',
      selectedLocale: localStorage.getItem('SELECTED_LOCALE') || 'en',
      subscriptionPackage: {},
      messageBalance: {},
      hasSubscription,
    };
  },

  computed: {
    formatBalance() {
      return (val) => {
        const d = this.$constants.DECIMAL_PLACES;
        const l = this.$constants.LOCALE;

        if (!val) { return '0.00'; }

        return this.$helper.formatPrice(val, d, l);
      };
    },
  },

  watch: {
    userData(newVal) {
      if (newVal.Subscription) {
        if (newVal.Subscription.Package && !Array.isArray(newVal.Subscription.Package)) {
          // eslint-disable-next-line prefer-destructuring
          this.subscriptionPackage = newVal.Subscription.Package.subscriptions[0];
        } else {
          this.subscriptionPackage = newVal.Subscription.Package;
        }
      }

      if (this.isSubscriptionActive()) {
        this.getBalance();
      }
    },

  },


  methods: {
    ...mapActions({
      getMessageBalance: 'common/getMessageBalance',
    }),

    getUserAlias(userData) {
      if (Object.keys(userData).length) {
        if (userData.Firstname && userData.Lastname) {
          const firstName = userData.Firstname.trim().charAt(0);
          const lastName = userData.Lastname.trim().charAt(0);

          return `${firstName}${lastName}`.toUpperCase();
        }

        if (userData.Firstname && !userData.Lastname) {
          return userData.Firstname.trim().charAt(0).toUpperCase();
        }

        if (!userData.Firstname && userData.Lastname) {
          return userData.Lastname.trim().charAt(0).toUpperCase();
        }

        if (userData.Login) {
          return userData.Login.charAt(0).toUpperCase();
        }
      }

      return '';
    },

    getCurrentTimeZoneDate() {
      return moment().utcOffset(this.userData.TimeZoneGMTOffset / 60).format();
    },

    hasSupportEntitlement() {
      return handlePackageLimit('cp-support');
    },

    isSubscriptionActive() {
      if (Array.isArray(this.subscriptionPackage)) {
        return false;
      }

      const { status } = this.subscriptionPackage;

      return status === 'ACTIVE';
    },

    openWidgetDropdown(selectedWidget = '') {
      if (selectedWidget === 'subscription') {
        this.getBalance();
      }
      this.selectedDropdown = selectedWidget;
      this.isOpen = true;
    },

    closeWidgetDropdown() {
      this.selectedDropdown = '';
      this.isOpen = false;
    },

    selectLocale(code) {
      this.selectedLocale = code;
      localStorage.setItem('SELECTED_LOCALE', this.selectedLocale);

      // this.$i18n.locale = this.selectedLocale;
      if (window.analytics) {
        let userData = {};
        try {
          userData = JSON.parse(localStorage.getItem('CPV3_User'));
        } catch (e) {
          // do nothing;
        }

        window.analytics.track('New Locale Selected', {
          Email: userData.Email,
          SelectedLocale: this.selectedLocale,
        }, () => {
          window.location.href = window.location.href;
        });
      } else {
        window.location.href = window.location.href;
      }
      return false;

      // const event = new Event('localeChanged');
      // window.dispatchEvent(event);
    },

    getLocaleName(selected) {
      const s = languages.find(v => v.code === selected);
      return s.alias;
    },

    getFlag(selected) {
      const s = languages.find(v => v.code === selected);
      return s.flag;
    },


    getLocales() {
      return languages;
    },

    getBalance() {
      const { AccountUid: accountUid } = this.userData;
      if (!this.subscriptionPackage) {
        this.messageBalance = {
          sms: { chargeable: 0 },
          whatsapp: { chargeable: 0 },
        };
      } else if (accountUid) {
        this.getMessageBalance({ accountUid, timeZoneOffset: 0 })
          .then(async (data) => {
            this.messageBalance = data;
          })
          .catch((err) => {
            this.$showError(this, err);
          });
      }
    },

    formatDate(date, noDay) {
      const newDate = date ? new Date(date) : new Date();
      const day = newDate.toLocaleString('default', { day: 'numeric' });
      const month = newDate.toLocaleString('default', { month: 'long' });
      const year = newDate.getFullYear();

      return noDay ? `${month} ${year}` : `${month} ${day}, ${year}`;
    },

    getEntitlementValue(entitlements, featureId) {
      const entitlement = entitlements.find(e => e.feature.id === featureId);

      if (!entitlement) {
        return 0;
      }
      return entitlement.usageLimit;
    },

  },
};
